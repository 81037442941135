import domtoimage from 'dom-to-image';

import { clientData } from './clients';
import locationsArray from './locationsArray';

const setLocOwners = (ownersObj, owner, subOwner, locName, compare) => {
  if (compare === false) return ownersObj; // if location has 'compare false' exclude from ownersObj

  if (subOwner[0].length !== 0) {
    if (typeof(ownersObj[owner]) !== 'undefined') {
			typeof(ownersObj[owner][subOwner[0]]) !== 'undefined' ? ownersObj[owner][subOwner[0]].push(locName) : ownersObj[owner][subOwner[0]] = [locName];
    }
    else {
      ownersObj[owner] = {
      	[subOwner[0]]: [locName]
      };
    }
  }
  else {
    typeof(ownersObj[owner]) !== 'undefined' ? ownersObj[owner].push(locName) : ownersObj[owner] = [locName];
  }

  return ownersObj;
}

export const getLocationInfo = (locName) => {
  const returnArray = locationsArray.filter(loc => locName === loc.name);
  if (!returnArray[0]) return []; // if location not found or location had no report field, return empty array.

  return returnArray[0]; // return object for 1 specific location
}

export const getLocations = (user) => {
  let [filterLocations, locationList, labelList, locAbbreviation, locIds, colors, childLocs, parentLocs, noCompare, qNames, subLocs, subsubLocs] = [[], [], [], [], [], [], [], [], [], [], [], []];
  let [coordinates, startDates, endDates, locGroupLbls, occupancy, pLocs, cLocs, mapRegions] = [{}, {}, {}, {}, {}, {}, {}, {}];
  let [timeStart, timeEnd, hrStart, hrEnd] = [{}, {}, {}, {}];
  // let [locCategories, locOwners] = [{ 'Downtown': [], 'Shopping Malls': [], 'Outlet Centers': [] }, {}];
  let [categoryTypesObj, aggregationObj, locCategoriesObj, locOwners] = [{}, {}, {}, {}];

  let [fullObj, defaultOpen] = [{}, {}];
  let [fullArray, vehicleArray, peopleArray, occupancyArray, vehicleTypeArray] = [[], [], [], [], []];

  const COMBCOLORS = [
    '#fbbf45', '#a12a5e', '#110141', '#01545a', '#26294a', '#017351', '#ef6a32', '#710162', '#ed0345', '#1a1334', '#03c383', '#aad962',
    '#fbbf45', '#a12a5e', '#110141', '#01545a', '#26294a', '#017351', '#ef6a32', '#710162', '#ed0345', '#1a1334', '#03c383', '#aad962',
    '#fbbf45', '#a12a5e', '#110141', '#01545a', '#26294a', '#017351', '#ef6a32', '#710162', '#ed0345', '#1a1334', '#03c383', '#aad962',
    '#fbbf45', '#a12a5e', '#110141', '#01545a', '#26294a', '#017351', '#ef6a32', '#710162', '#ed0345', '#1a1334', '#03c383', '#aad962',
  ];

  if (user.client.toLowerCase() === 'all') {
    filterLocations = locationsArray; // admin 'all' view
  }
  else if (user.locations && user.locations.length) {
    filterLocations = locationsArray.filter(loc => user.locations.includes(loc.locId)); // locations from jwt array
  }
  else {
    filterLocations = locationsArray.filter(loc => user.client.toLowerCase() === loc.client.toLowerCase()); // locations by client from jwt
  }

  // client start/end time and hr, only used if specific location is missing start/end time or hr
  const clientStartTime = clientData[user?.client?.toLowerCase()]?.byTimeStart ?? '2019-01-01T14:00:00.000Z'; // fallback 9am EST (adjusted for dst);
  const clientEndTime = clientData[user?.client?.toLowerCase()]?.byTimeEnd ?? '2019-01-02T03:00:00.000Z'; // fallback 10pm EST
  const clientHrStart = clientData[user?.client?.toLowerCase()]?.hrStart ?? 0;
  const clientHrEnd = clientData[user?.client?.toLowerCase()]?.hrEnd ?? 24;

  const clientCategories = clientData[user?.client?.toLowerCase()]?.categories ?? {};

  filterLocations.forEach(val => {
    if (val.locId) {
      const refName = (val.qName && !Array.isArray(val.qName)) ? val.qName : val.name;
      
      timeStart[refName] = val.byTimeStart || clientStartTime; // if loc missing start/end time use client's start/end time
      timeEnd[refName] = val.byTimeEnd || clientEndTime;
      hrStart[refName] = val.hrStart || clientHrStart; // if loc missing start/end hr use client's start/end hr
      hrEnd[refName] = val.hrEnd || clientHrEnd;
      locationList.push(val.name);
      labelList.push(val.label);
      locAbbreviation.push(val.abbreviation);
      locIds.push(val.locId);
      (val.qName) ? qNames.push(val.qName) : qNames.push(val.name); // use location name if no qName
      colors.push(val.color);
      // locCategories[val.categories].push(val.name);
      locOwners = setLocOwners(locOwners, Object.keys(val.owners), Object.values(val.owners), val.name, val.compare);
      // sensorCoverage.push(val.sensorCoverage);
      // sensorLoc.push(val.sensorLocation);
      if (val.child) childLocs.push(val.name);
      if (val.parent) parentLocs.push(val.name);
      startDates[val.name] = val.startDate;
      endDates[val.name] = val.endDate;
      if (val.compare === false) noCompare.push(val.name);
      coordinates[val.name] = val.location?.coordinates ?? [];
      if (val.subLocation === true) subLocs.push(val.name);
      if (val.subsubLocation === true) subsubLocs.push(val.name);
      if (val.groupLabel) locGroupLbls[val.name] = val.groupLabel;
      occupancy[val.name] = (val.occupancy === true) ? true : false;
      if (val.parentOf) pLocs[val.name] = val.parentOf;
      if (val.childOf) cLocs[val.name] = val.childOf;
      if (val.mapRegion) mapRegions[val.name] = val.mapRegion
    }

    if (val.defaultOpen === true) defaultOpen[val.name] = true;

    fullObj[val.name] = val;
    fullArray.push(val);

    if (val.traffic?.includes('vehicle')) vehicleArray.push(val);
    if (val.traffic?.includes('people') || !(val.traffic)) peopleArray.push(val);

    if (val.vehicles) vehicleTypeArray.push(val.vehicles);
    if (val.occupancy) occupancyArray.push(val);
  });

  // Get Category, Category Type, and Category Aggregation Type data.
  if (Object.keys(clientCategories).length) {
    const categoryTypesArray = [];

    Object.keys(clientCategories).forEach(c => {
      categoryTypesArray.push(Object.keys(clientCategories[c].types)); // [['Downtown', 'Outlet Centers', 'Shopping Malls'],[],[]]
      categoryTypesObj[c] = Object.keys(clientCategories[c].types); // { 'Retail Type': ['Downtown', 'Outlet Centers', 'Shopping Malls'], x: [], y: [] }
      aggregationObj[c] = clientCategories[c].aggregation; // category aggregation

      Object.keys(clientCategories[c].types).forEach(t => {
        aggregationObj[t] = clientCategories[c].types[t];  // category type aggregation - { 'Downtown': 'total', 'Outlet Centers': 'total', 'Shopping Malls': 'total', 'Retail Type': 'average' }
      })
    });

    locCategoriesObj = categoryTypesArray.flat().reduce((acc, category) => ({ ...acc, [category]: [] }), {}); // { 'Downtown': [], 'Outlet Centers': [], 'Shopping Malls': [] }

    filterLocations.forEach(loc => {
      if (loc.locId && loc.categories) {
        loc.categories.forEach(c => {
          if (locCategoriesObj[c]) locCategoriesObj[c].push(loc.name); // { 'Downtown': ['NYC - Empire'], 'Outlet Centers': ['Woodbury Common'], 'Shopping Malls': ['King of Prussia'] }
        });
      }
    });
  }

  const locationsObject = {
    // 'sensorCoverage': sensorCoverage,
    // 'sensorLoc': sensorLoc,
    'locationList': locationList,
    'labelList': labelList,
    'locAbbreviation': locAbbreviation,
    'locIds': locIds,
    'qNames': qNames,
    'locCategories': locCategoriesObj,
    'locCategoryTypes': categoryTypesObj,
    'aggregationType': aggregationObj,
    'locOwners': locOwners,
    'colors': colors,
    'COMBCOLORS': COMBCOLORS,
    'childLocs': childLocs,
    'parentLocs': parentLocs,
    'startDates': startDates,
    'endDates': endDates,
    'noCompare': noCompare,
    'coordinates': coordinates,
    'subLocs': subLocs,
    'subsubLocs': subsubLocs,
    'locGroupLbls': locGroupLbls,
    'byTime': {
      'start': timeStart,
      'end': timeEnd
    },
    'byHr': {
      'start': hrStart,
      'end': hrEnd
    },
    'occupancy': occupancy,
    'pLocs': pLocs,
    'cLocs': cLocs,

    'defaultOpen': defaultOpen,
    'fullDetails': fullObj,
    'fullArray': fullArray,

    'locationTypes': {
      ...(peopleArray.length) && { 'People': peopleArray },
      ...(vehicleArray.length) && { 'Vehicles': vehicleArray },
      ...(occupancyArray.length) && { 'Occupancy': occupancyArray },
    },
    'vehicleTypes': [...new Set(vehicleTypeArray.flat())], // flattens array and removes duplicate values
    'mapRegions': mapRegions,
  };

  return locationsObject;
}

export const reduceArray = (dataArray, numPlots, multDate, secondDateCardIndex) => {
  let chartArray = [];

  for (let i = 0, len = numPlots; i < len; i++) {
    chartArray[i] = dataArray[i];
  }

  if (multDate) chartArray.push(dataArray[secondDateCardIndex]);

  return chartArray;
};

export const reduceArrayAverages = (dataArray, numPlots, multDate, secondDateCardIndex) => {
  let chartArray = [];
  let adjustNumPlots = (multDate) ? numPlots - 1 : numPlots; // AveragesHeader numPlots prop includes 2nd date in numplots

  for (let i = 0, len = adjustNumPlots; i < len; i++) {
    chartArray[i] = dataArray[i];
  }

  if (multDate) chartArray.push(dataArray[secondDateCardIndex]);

  return chartArray;
};

export const getColor = (label, idx, locationsObject, series) => {
  if (typeof (label) !== 'undefined' && label.length > 0) {
    if (label.length === 1) {
      label = label[0];
    }

    // vehicle locs can include a list of filtered v-types surrounded by [] in label.
    label = label.replace(/ \[.*\]/, ''); // remove v-types if included, otherwise a combo color will be returned.

    let locInd = Object.values(locationsObject['labelList']).indexOf(label);

    if (series[parseInt(idx)].aggregationType === 'single' && locInd > -1) {
      return locationsObject['colors'][locInd];
    }

    if (parseInt(idx) >= 0 && label.length > 0) {
      let comboNum = 0;

      for (let i = 0; i < parseInt(idx) + 1; i++) {
        let lbl = (typeof (series[i].locationLabels) !== 'undefined') ? series[i].locationLabels[0] : '-';
        let locInd2 = Object.values(locationsObject['labelList']).indexOf(lbl);

        if (series[i].aggregationType === 'total' || series[i].aggregationType === 'average' || locInd2 < 0) {
          comboNum = (/_2_1$/.test(series[i].name)) ? comboNum : comboNum + 1; // if location is 2nd date range keep color same as previous location.
        }
      }

      if (comboNum === 0) {
        comboNum = 1;
      }

      return '#26294a';
      // return locationsObject['COMBCOLORS'][comboNum - 1];
    }
  }

  return '#FFFFFF';
}

export const saveImage = async (node, fileObj, byDate) => {
  const fileStr = fileObj.chartName.concat(fileObj.dateLabel, '_', fileObj.count ? 'Counts' : '', fileObj.gender ? 'Gender' : '', fileObj.age ? 'Age' : '', '.' + fileObj.fileType).replace(/\s/g, '');
  const options = {
    ...(fileObj.fileType !== 'svg') && { quality: 0.95 }, // omit quality option for svg
    bgcolor: 'white',
    width: (byDate) ? (node.clientWidth * 1.02) : (node.clientWidth),
    height: (byDate) ? (node.clientHeight * 1.0) : (node.clientHeight * 1.06),
    style: {
      marginLeft: (byDate) ? ((node.clientWidth * 0) + 'px') : ((node.clientWidth * 0.02) + 'px'),
      marginTop: (byDate) ? ((node.clientHeight * 0 + 5) + 'px') : ((node.clientHeight * 0.02) + 'px'),
    }
  }

  const dataUrl = (fileObj.fileType === 'svg') ? await domtoimage.toSvg(node, options) : await domtoimage.toJpeg(node, options);

  var link = document.createElement('a');
  link.download = fileStr;
  link.href = dataUrl;
  link.click();

  window.analytics.track('Export Image', { data: fileStr });
};

export const getCordinates = (locLbl, locObj) => {
  if (locLbl.length > 1) return { lat: '', lon: '' };

  const cordinates = locObj?.coordinates?.[locLbl[0]] ?? [];
  if (!cordinates.length) return { lat: '', lon: '' };

  return {
    lat: cordinates[1] ?? '',
    lon: cordinates[0] ?? ''
  };
}

export const darkenColor = (hex, percent) => {
  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);

  r = Math.max(0, Math.floor(r * (1 - percent / 100)));
  g = Math.max(0, Math.floor(g * (1 - percent / 100)));
  b = Math.max(0, Math.floor(b * (1 - percent / 100)));

  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
};
